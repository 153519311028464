import React, { useState, Component } from 'react';
import validator from 'validator'
import FirstStepForm from './FirstStepForm';
import SecondStepForm from './SecondStepForm';
import ThirdStepForm from './ThirdStepForm';
import FourthStepForm from './FourthStepForm';
import Success from './Success';
import axios from 'axios';

import './Signup.css';

export default class Signup extends Component {

    state = {
        step: 1,
        email: '',
        fullName: '',
        accountName: '',
        companySize: '',
        teamSize: '',
        hearAboutUs: '',
        emailError: '',
        isEmailValid: false,
        haveAnAccount: false,
        fullNameError: '',
        isFullNameValid: false,
        accountNameError: '',
        isAccountNameValid: false,
        teamListArray: ['2-5', '6-10', '11-15', '16-25', '25-50', '51-100', '101-500', '500+'],
        teamListClicked: false,
        teamListIndex: 0,
        companyListArray: ['1-10', '11-49', '50-99', '11-15', '100-250', '251-500', '501-1500', '1500+'],
        companyListClicked: false,
        companyListIndex: 0,
        hearAboutUsListArray: ['Billboard / Public transit ad',
            'Audio ad (Native podcast, Spotify, TV)',
            'Friend / Colleague', 'Software review sites', 'Big Game ad', 'Linkedin', 'Search engine (Google, Bing, etc.)',
            'Youtube ad', 'Social media (Facebook, Instagram, Reddit, etc.)', 'Other'],
        hearAboutUsListClicked: new Array(10).fill(false),
        hearAboutUsListIndex: 0,
    }


    // go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    // proceed to the next step
    nextStep = () => {
        const { step } = this.state;
        if (step === 4) {
            this.onSubmit();
            return;
        }
        this.setState({ step: step + 1 });
    }


    handleChange = input => e => {
        if (input === 'email') {
            if (validator.isEmail(e.target.value)) {
                this.setState({ ['isEmailValid']: true });
            } else {
                this.setState({ ['isEmailValid']: false });
            }
            this.setState({ ['emailError']: '' });
        }
        if (input === 'fullName') {
            if (e.target.value.trim().length >= 3) {
                this.setState({ ['isFullNameValid']: true });
            } else {
                this.setState({ ['isFullNameValid']: false });
            }
            this.setState({ ['fullNameError']: '' });
        }
        if (input === 'accountName') {
            if (e.target.value.trim().length >= 3) {
                this.setState({ ['isAccountNameValid']: true });
            } else {
                this.setState({ ['isAccountNameValid']: false });
            }
            this.setState({ ['accountNameError']: '' });
        }
        this.setState({ [input]: e.target.value });
    }


    handleErrorChange = (field, error) => {
        this.setState({ [field]: error });
    }

    handleClick = (fieldClicked, fieldIndex, field, value, index) => {
        this.setState({
            [fieldClicked]: true,
            [fieldIndex]: index,
            [field]: value
        });


    }

    handleCheckboxAccountClick = () => {
        this.setState({ ['haveAnAccount']: !this.state.haveAnAccount });
    }

    handleCheckboxClick = (position, item) => {
        const updatedCheckedState = this.state.hearAboutUsListClicked.map((item, index) =>
            index === position ? !item : item
        );
        this.setState({ ['hearAboutUsListClicked']: updatedCheckedState });

        const hearAboutUs = [];
        updatedCheckedState.map(
            (currentState, index) => {
                if (currentState === true) {
                    hearAboutUs.push(this.state.hearAboutUsListArray[index])
                }
            });
        this.setState({ ['hearAboutUs']: hearAboutUs });
    };

    getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    async onSubmit() {
        try {
            const url = "https://api-prod.mondayaws.com" + '/account/create'; //process.env.REACT_APP_BASE_DEVELOP_URL
            let regToken = this.getUrlParameter('x-amzn-marketplace-token');
            if (!regToken) {
                alert('danger,Registration Token Missing. Please go to AWS Marketplace and follow the instructions to set up your account!');
                regToken = "token"
            }
            else {
                const contactData = {
                    email: this.state.email,
                    fullName: this.state.fullName,
                    accountName: this.state.accountName,
                    haveAnAccount: this.state.haveAnAccount,
                    token: regToken
                }
                if (this.state.teamSize.trim().length > 0) {
                    contactData.teamSize = this.state.teamSize;
                }
                if (this.state.companySize.trim().length > 0) {
                    contactData.companySize = this.state.companySize;
                }
                if (this.state.hearAboutUs.length > 0) {
                    contactData.hearAboutUs = this.state.hearAboutUs;
                }

                console.log(contactData)
                const res = await axios.post(url, contactData);
                const { step } = this.state;
                if (res.status === 201) {
                    this.setState({ step: step + 1 });
                    console.log(this.state.step);
                } else {
                    alert('An error has occurred, please try again later or contact the developer');
                    return;
                }
            }
        } catch (error) {
            console.log(error)
            alert('An error has occurred, please try again later or contact the developer');
            throw new Error("Error has occurred while creating contact!")
        }
    };

    render() {
        const { step } = this.state;
        const { email, fullName, accountName, companySize, teamSize, hearAboutUs,
            emailError, fullNameError, accountNameError, teamListArray, teamListClicked,
            teamListIndex, teamListValue, companyListArray, companyListClicked, companyListIndex,
            hearAboutUsListArray, hearAboutUsListClicked,
            hearAboutUsListIndex, isEmailValid, isFullNameValid,
            isAccountNameValid } = this.state;
        const values = {
            email, fullName, accountName, companySize, teamSize, hearAboutUs,
            emailError, fullNameError, accountNameError, teamListArray, teamListClicked,
            teamListIndex, teamListValue, companyListArray, companyListClicked, companyListIndex,
            hearAboutUsListArray, hearAboutUsListClicked,
            hearAboutUsListIndex, isEmailValid, isFullNameValid, isAccountNameValid,


        }
        switch (step) {
            case 1:
                return (
                    <FirstStepForm
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        handleErrorChange={this.handleErrorChange}
                        handleCheckboxAccountClick={this.handleCheckboxAccountClick}
                        values={values}
                    />
                )
            case 2:
                return (
                    <SecondStepForm
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        handleErrorChange={this.handleErrorChange}
                        values={values}
                    />
                )
            case 3:
                return (
                    <ThirdStepForm
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleClick={this.handleClick}
                        values={values}
                    />
                )
            case 4:
                return (
                    <FourthStepForm
                        prevStep={this.prevStep}
                        nextStep={this.nextStep}
                        handleCheckboxClick={this.handleCheckboxClick}
                        values={values}
                    />
                )
            case 5:
                return (
                    <Success />
                )
            default:
            // do nothing
        }
    }
}


