import React from 'react';

import './FourthStepForm.css';

const FourthStepForm = ({ prevStep, nextStep, handleCheckboxClick, values }) => {

    const Continue = (event) => {
        event.preventDefault();
        nextStep();
    }

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }


    return (
        <div >
            <div className="container-fourth-step">
                <div className="row">
                    <div className="monday-logo" ></div>
                    <div className='new-contact-items-fourth'>
                        <div className='new-contact-item'>
                            <div className="list-title list-title-fourth">One last question, how did you <strong>hear about us?</strong></div>
                            <div className="checkbox-container" >
                                {values.hearAboutUsListArray.map((item, index) => (
                                    <div  className={`checkbox-item-fourth ${ values.hearAboutUsListClicked[index] > 0 ? 'checked':''} `} key={index}>
                                        <input className="input-checkbox" value={item}
                                            type="checkbox"
                                            checked={values.hearAboutUsListClicked[index]}
                                            onChange={() => handleCheckboxClick(index, item)} />
                                        <span>{item}</span>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                    <div className="row row-buttons row-buttons-fourth">
                        <div className="col-md-6">
                            <button className="back-button" onClick={Previous}>Back</button>
                        </div>
                        <div className="col-md-6 continue-button-wrapper">
                            <button className='valid-fourth-step' onClick={Continue}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default FourthStepForm;