import React from 'react';
import './ThirdStepForm.css';

const ThirdStepForm = ({ prevStep, nextStep, handleClick, values }) => {


    const Continue = (event) => {
        event.preventDefault();
        nextStep();
    }

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }


    return (
        <div>
            <div className="container-third-step">
                <div className="row">
                    <div className="monday-logo" ></div>
                    <div className='new-contact-items-third'>
                        <div className='new-contact-item'>
                            <div className="list-title">How many people are on your <strong>team?</strong></div>
                            <div className="list-second-title" >The platform can also scale as your team grows</div>
                            <ul>
                                {values.teamListArray.map((item, i) => (
                                    <li key={i} className={(values.teamListClicked === true && values.teamListIndex === i) ? 'clicked' : ''} onClick={evt => { handleClick('teamListClicked', 'teamListIndex', 'teamSize', item, i) }}>{item}</li>
                                ))}
                            </ul>


                        </div>
                        <div className='new-contact-item-third'>
                            <div className="list-title">How many people work at your <strong>company?</strong></div>
                            <div className="list-second-title" >monday.com is fit for any business size</div>
                            <ul>
                                {values.companyListArray.map((item, i) => (
                                    <li key={i} className={(values.companyListClicked === true && values.companyListIndex === i) ? 'clicked' : ''} onClick={evt => { handleClick('companyListClicked', 'companyListIndex', 'companySize', item, i) }}>{item}</li>
                                ))}
                            </ul>

                        </div>
                    </div>
                    <div className="row row-buttons-third">
                        <div className="col-md-6">
                            <button className="back-button" onClick={Previous}>Back</button>
                        </div>
                        <div className="col-md-6 continue-button-wrapper">
                            <button  className='valid-Third-step' onClick={Continue}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ThirdStepForm;