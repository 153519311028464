import React from 'react';

import './SecondStepForm.css';

const SecondStepForm = ({ prevStep, nextStep, handleChange, handleErrorChange, values }) => {

    const Continue = (event) => {
        event.preventDefault();
        if (values.fullName.trim().length < 3) {
            handleErrorChange('fullNameError', 'Name should be longer than 3 characters');
            return;
        }
        if (values.accountName.trim().length < 3) {
            handleErrorChange('accountNameError', 'Invalid account');
            return;
        }
        nextStep();
    }

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }

    return (
        <div>
            <div className="container-second-step">
                <div className="row">
                    <div className="col-md-8 text-container">
                        <div className='popup-title'>Set up your account</div>
                        <div className='popup-sm-title'>Fill in your profile details</div>
                        <div className='new-contact-items'>
                            <div className='new-contact-item'>
                                <label>Full Name</label>
                                <input className={`${ values.isFullNameValid? 'valid-full-name':''} `} type='text' value={values.fullName} onChange={handleChange('fullName')} />
                                <span className="error-text">{values.fullNameError}</span>
                            </div>
                            <div className='new-contact-item'>
                                <label>Account Name</label>
                                <input className={`${ values.isAccountNameValid? 'valid-account-name':''} `} type='text' value={values.accountName} onChange={handleChange('accountName')} />
                                <span className="error-text">{values.accountNameError}</span>
                            </div>
                        </div>
                        <div className="row row-buttons">
                            <div className="col-md-6">
                                <button className="back-button" onClick={Previous}>Back</button>
                            </div>
                            <div className="col-md-6 continue-button-wrapper">
                                <button className={`${ values.isFullNameValid && values.isAccountNameValid ? 'valid-second-step':''} `} onClick={Continue}>Continue</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 popup-pic-container-second">
                    <div className='popup-pic-second'></div>
                       <div className='popup-pic-text'>Fill in your details so you can log in later</div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SecondStepForm;