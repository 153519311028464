import React from 'react';
import validator from 'validator'

import './FirstStepForm.css';

const FirstStepForm = ({ nextStep, handleChange, handleErrorChange, handleCheckboxAccountClick, values }) => {

    const Continue = (event) => {
        event.preventDefault();
        if (!validator.isEmail(values.email)) {
            handleErrorChange('emailError', 'Invalid Email')
            return;
        }
        nextStep();
    }

    return (
        <div>
            <div className="container-first-step" >
                <div class="row">
                    <div className="col-md-8 text-container">
                        <div className='popup-title'>Welcome to monday.com</div>
                        <div className='popup-sm-title'>Get Started!</div>
                        <div className='new-contact-items'>
                            <div className='new-contact-item'>
                                <label>Enter Email</label>
                                <input className={`${ values.isEmailValid? 'valid-email':''} `} type='text' id="userEmail" value={values.email} onChange={handleChange('email')} ></input>
                                <span className="error-text">{values.emailError}</span>
                            </div>
                        </div>
                        <button className={`${ values.isEmailValid? 'valid-email':''} `} onClick={Continue}>Continue</button>

                        <div className="checkbox-item checkbox-account" >
                            <input className="input-checkbox"
                                type="checkbox"
                                onChange={() => handleCheckboxAccountClick()} />
                            <span>Already have an account</span>
                        </div>
                    </div>

                    <div className="col-md-4 popup-pic-container">
                       <div className='popup-pic'></div>
                       <div className='popup-pic-text'>Join over 125,000 teams that manage their work better</div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default FirstStepForm;