import React from 'react';

import './Success.css';

const Success = (props) => {

    setTimeout(function(){
        window.location.href = 'https://www.monday.com/';
     }, 5000);

    return (
        <div>
            <div className="container-success-step">
                <div className="row">
                    <div className="monday-logo" ></div>
                    <div className="success-logo" ></div>
                    <div className="success-title">Thanks for submitting your request!</div>
                    <div className='success-text'>
                        Thanks for submitting! Now wait for a confirmation email with <br />
                        further instructions in your inbox <br />
                        (check the promotional/spam folders in case you don’t see it)
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Success;