import logo from './logo.svg';
import './App.css';
import ContactForm from './components/ContactForm';
import Signup from './components/Signup';

function App() {
  return (
   <Signup></Signup>
  );
}

export default App;
